
:-moz-ui-invalid {
  -webkit-box-shadow: none;
  box-shadow: none;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@font-face {
  font-family: "Avenir Next W01";
  src: url("https://static.contentful.com/app/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"), url("https://static.contentful.com/app/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next W01";
  src: url("https://static.contentful.com/app/7377dbe6-f11a-4a05-b33c-bc8ce1f60f84.woff2") format("woff2"), url("https://static.contentful.com/app/92b66dbd-4201-4ac2-a605-4d4ffc8705cc.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next W01";
  src: url("https://static.contentful.com/app/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2") format("woff2"), url("https://static.contentful.com/app/f26faddb-86cc-4477-a253-1e1287684336.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
body {
  font-family: "Avenir Next W01", Helvetica, sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  line-height: 18px;
  color: #2d2f31;
}
b,
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
a {
  color: #4a90e2;
  cursor: pointer;
  text-decoration: none;
}
a:link {
  color: #1683d0;
  text-decoration: none;
}
a:hover {
  color: #0c2c52;
}
.cf-text-dimmed {
  color: #808080;
}
@-moz-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.cf-btn-primary {
  display: inline-block;
  padding: 0.625rem 1.5rem;
  cursor: pointer;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 2px;
  line-height: 18px;
  color: #fff;
  background: -webkit-linear-gradient(bottom, #3c80cf, #5b9fef);
  background: -moz-linear-gradient(bottom, #3c80cf, #5b9fef);
  background: -o-linear-gradient(bottom, #3c80cf, #5b9fef);
  background: -ms-linear-gradient(bottom, #3c80cf, #5b9fef);
  background: linear-gradient(to top, #3c80cf, #5b9fef);
  -webkit-background-size: 100% 200%;
  -moz-background-size: 100% 200%;
  background-size: 100% 200%;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.1s;
  -moz-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  -ms-transition-duration: 0.1s;
  transition-duration: 0.1s;
  border-color: #3c80cf;
}
.cf-btn-primary:hover {
  background-position: 0 100%;
}
.cf-btn-primary.active,
.cf-btn-primary:active {
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.12);
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.12);
}
.cf-btn-primary:link,
a.cf-btn-primary:hover,
a.cf-btn-primary:link,
a.cf-btn-primary:visited {
  color: #fff;
}
.cf-btn-primary:disabled,
.cf-btn-primary[aria-disabled=true] {
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  cursor: default;
  background: -webkit-linear-gradient(bottom, #3c80cf, #5b9fef);
  background: -moz-linear-gradient(bottom, #3c80cf, #5b9fef);
  background: -o-linear-gradient(bottom, #3c80cf, #5b9fef);
  background: -ms-linear-gradient(bottom, #3c80cf, #5b9fef);
  background: linear-gradient(to top, #3c80cf, #5b9fef);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cf-btn-primary > * {
  display: inline-block;
}
.cf-btn-primary:after {
  content: '';
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  border: 0;
  width: 0;
  position: relative;
  top: -1px;
  vertical-align: middle;
  -webkit-transition: width 0.2s linear, margin 0.2s linear;
  -moz-transition: width 0.2s linear, margin 0.2s linear;
  -o-transition: width 0.2s linear, margin 0.2s linear;
  -ms-transition: width 0.2s linear, margin 0.2s linear;
  transition: width 0.2s linear, margin 0.2s linear;
  display: inline-block;
}
.cf-btn-primary.cf-is-loading:disabled {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.cf-btn-primary.cf-is-loading:after {
  -webkit-transition: width 0.2s linear, margin 0.2s linear, opacity 0.5s ease-in;
  -moz-transition: width 0.2s linear, margin 0.2s linear, opacity 0.5s ease-in;
  -o-transition: width 0.2s linear, margin 0.2s linear, opacity 0.5s ease-in;
  -ms-transition: width 0.2s linear, margin 0.2s linear, opacity 0.5s ease-in;
  transition: width 0.2s linear, margin 0.2s linear, opacity 0.5s ease-in;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  width: 10px;
  height: 10px;
  -webkit-animation: fa-spin 2s infinite linear;
  -moz-animation: fa-spin 2s infinite linear;
  -o-animation: fa-spin 2s infinite linear;
  -ms-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  border-radius: 50%;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  border-top: 2px solid rgba(255,255,255,0.6);
  border-right: 2px solid rgba(255,255,255,0.6);
  margin-left: 5px;
}
.cf-btn-primary:hover,
.cf-btn-primary.active {
  border-color: #3072be;
}
.cf-btn-secondary {
  display: inline-block;
  padding: 0.625rem 1.5rem;
  cursor: pointer;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 2px;
  line-height: 18px;
  color: #536171;
  background: #e5ebed;
  -webkit-background-size: 100% 200%;
  -moz-background-size: 100% 200%;
  background-size: 100% 200%;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.1s;
  -moz-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  -ms-transition-duration: 0.1s;
  transition-duration: 0.1s;
  border-color: #c3cfd5;
}
.cf-btn-secondary:hover {
  background-position: 0 100%;
}
.cf-btn-secondary.active,
.cf-btn-secondary:active {
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.12);
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.12);
}
.cf-btn-secondary:link,
a.cf-btn-secondary:hover,
a.cf-btn-secondary:link,
a.cf-btn-secondary:visited {
  color: #536171;
}
.cf-btn-secondary:disabled,
.cf-btn-secondary[aria-disabled=true] {
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  cursor: default;
  background: #e5ebed;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cf-btn-secondary > * {
  display: inline-block;
}
.cf-btn-secondary:after {
  content: '';
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  border: 0;
  width: 0;
  position: relative;
  top: -1px;
  vertical-align: middle;
  -webkit-transition: width 0.2s linear, margin 0.2s linear;
  -moz-transition: width 0.2s linear, margin 0.2s linear;
  -o-transition: width 0.2s linear, margin 0.2s linear;
  -ms-transition: width 0.2s linear, margin 0.2s linear;
  transition: width 0.2s linear, margin 0.2s linear;
  display: inline-block;
}
.cf-btn-secondary.cf-is-loading:disabled {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.cf-btn-secondary.cf-is-loading:after {
  -webkit-transition: width 0.2s linear, margin 0.2s linear, opacity 0.5s ease-in;
  -moz-transition: width 0.2s linear, margin 0.2s linear, opacity 0.5s ease-in;
  -o-transition: width 0.2s linear, margin 0.2s linear, opacity 0.5s ease-in;
  -ms-transition: width 0.2s linear, margin 0.2s linear, opacity 0.5s ease-in;
  transition: width 0.2s linear, margin 0.2s linear, opacity 0.5s ease-in;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  width: 10px;
  height: 10px;
  -webkit-animation: fa-spin 2s infinite linear;
  -moz-animation: fa-spin 2s infinite linear;
  -o-animation: fa-spin 2s infinite linear;
  -ms-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  border-radius: 50%;
  border-bottom: 2px solid #536171;
  border-left: 2px solid #536171;
  border-top: 2px solid rgba(135,150,167,0.6);
  border-right: 2px solid rgba(135,150,167,0.6);
  margin-left: 5px;
}
.cf-btn-secondary:hover,
.cf-btn-secondary.active {
  background-color: #d3dce0;
  border-color: #b4c3ca;
}
.cf-btn-secondary > cf-icon[name="plus"] circle {
  fill: #536171;
}
.cf-btn-secondary > cf-icon[name="plus"] path {
  stroke: #e5ebed;
}
.cf-btn-primary.cf-block,
.cf-btn-secondary.cf-block {
  display: block;
  width: 100%;
  position: relative;
}
.cf-btn-primary.cf-block:after,
.cf-btn-secondary.cf-block:after {
  position: absolute;
}
.cf-btn-primary.cf-block.cf-is-loading:after,
.cf-btn-secondary.cf-block.cf-is-loading:after {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  top: 11px;
}
.cf-form-field {
  margin-bottom: 1.928571428571429em;
}
.cf-form-field > label {
  display: block;
  margin-bottom: 0.642857142857143em;
}
.cf-form-hint {
  margin-top: 0.642857142857143em;
  color: #808080;
  font-size: 13px;
}
input.cf-form-input {
  font-size: 16px;
  color: #2a3039;
  font-family: "Avenir Next W01", Helvetica, sans-serif;
  display: block;
  width: 100%;
  line-height: 1.5;
  padding: 0 2px;
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: transparent;
  border-image-width: 0 0 1px 0;
  border-image-source: url("https://static.contentful.com/app/svg/dotted-border.svg");
  border-image-repeat: round;
  border-image-slice: 1 1;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  transition: 0.2s linear;
  -webkit-transition-property: padding, background-color;
  -moz-transition-property: padding, background-color;
  -o-transition-property: padding, background-color;
  -ms-transition-property: padding, background-color;
  transition-property: padding, background-color;
  height: 36px;
}
input.cf-form-input[aria-invalid="true"],
input.cf-form-input:invalid {
  background-color: #fff2f1;
}
input.cf-form-input[aria-disabled="true"],
input.cf-form-input[disabled],
input.cf-form-input:disabled {
  background-color: #f7f9fa;
  color: #a9b9c0;
  -webkit-border-image: none;
  -moz-border-image: none;
  -o-border-image: none;
  border-image: none;
}
input.cf-form-input[size] {
  width: auto;
}
textarea.cf-form-input {
  font-size: 16px;
  color: #2a3039;
  font-family: "Avenir Next W01", Helvetica, sans-serif;
  display: block;
  width: 100%;
  line-height: 1.5;
  padding: 0 2px;
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: transparent;
  border-image-width: 0 0 1px 0;
  border-image-source: url("https://static.contentful.com/app/svg/dotted-border.svg");
  border-image-repeat: round;
  border-image-slice: 1 1;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  transition: 0.2s linear;
  -webkit-transition-property: padding, background-color;
  -moz-transition-property: padding, background-color;
  -o-transition-property: padding, background-color;
  -ms-transition-property: padding, background-color;
  transition-property: padding, background-color;
  padding-top: 0.2em;
  height: 4.5em;
  max-width: 100%;
}
textarea.cf-form-input[aria-invalid="true"],
textarea.cf-form-input:invalid {
  background-color: #fff2f1;
}
textarea.cf-form-input[aria-disabled="true"],
textarea.cf-form-input[disabled],
textarea.cf-form-input:disabled {
  background-color: #f7f9fa;
  color: #a9b9c0;
  -webkit-border-image: none;
  -moz-border-image: none;
  -o-border-image: none;
  border-image: none;
}
select.cf-form-input {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  cursor: inherit;
  background: none;
  line-height: normal;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  color: #2a3039;
  font-family: "Avenir Next W01", Helvetica, sans-serif;
  display: block;
  width: 100%;
  line-height: 1.5;
  padding: 0 2px;
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: transparent;
  border-image-width: 0 0 1px 0;
  border-image-source: url("https://static.contentful.com/app/svg/dotted-border.svg");
  border-image-repeat: round;
  border-image-slice: 1 1;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  transition: 0.2s linear;
  -webkit-transition-property: padding, background-color;
  -moz-transition-property: padding, background-color;
  -o-transition-property: padding, background-color;
  -ms-transition-property: padding, background-color;
  transition-property: padding, background-color;
  width: auto;
  padding-right: 1.5em;
  padding-left: 0.5em;
  height: 36px;
  background-image: url("https://static.contentful.com/app/svg/dd-arrow-down.svg");
  background-position: center right 5px;
  background-repeat: no-repeat;
  -webkit-background-size: 0.7em;
  -moz-background-size: 0.7em;
  background-size: 0.7em;
}
select.cf-form-input::-ms-expand {
  display: none;
}
select.cf-form-input[aria-invalid="true"],
select.cf-form-input:invalid {
  background-color: #fff2f1;
}
select.cf-form-input[aria-disabled="true"],
select.cf-form-input[disabled],
select.cf-form-input:disabled {
  background-color: #f7f9fa;
  color: #a9b9c0;
  -webkit-border-image: none;
  -moz-border-image: none;
  -o-border-image: none;
  border-image: none;
}
select.cf-form-input > option {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cf-field-error {
  margin-top: 0.642857142857143em;
  color: #f05650;
}
.cf-form-option {
  margin-bottom: 1.285714285714286em;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.cf-form-option:last-child {
  margin-bottom: 0;
}
.cf-form-option > input[type="checkbox"],
.cf-form-option > input[type="radio"] {
  -webkit-align-self: flex-start;
  align-self: flex-start;
  -ms-flex-item-align: start;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  margin: 1px 0.5em 0 0;
}
.cf-form-option > label {
  display: block;
  cursor: pointer;
}
.cf-form-label p {
  font-size: 13px;
  margin: 0;
}
.cf-form-label label {
  line-height: 1.928571428571429em;
  margin-top: -0.642857142857143em;
  display: inline-block;
  cursor: pointer;
}
.cf-form-option > input[type="checkbox"]:disabled + label {
  color: #808080;
}
.cf-form-horizontal {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.cf-form-horizontal .cf-form-option {
  margin-right: 2.571428571428572em;
}
.cf-form-horizontal .cf-form-option:last-child {
  margin-right: 0;
}
